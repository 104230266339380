import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  shape,
  string,
  oneOf,
  object,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber, formattedMoney } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  FieldSelect,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
} from './ListingPage.duck';
import SectionAvatar from './SectionAvatar';
import ActionBarMaybe from './ActionBarMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import LayoutWrapperDashboardSideNav from '../../components/LayoutWrapperAccountSettingsSideNav/LayoutWrapperDashboardSideNav';
const maxTimeSlots = 90;

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formattedMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      saleOrRent: "forRent",
      bookmarks:
        props.currentUser &&
          props.currentUser.attributes &&
          props.currentUser.attributes.profile &&
          props.currentUser.attributes.profile.publicData &&
          props.currentUser.attributes.profile.publicData.bookmarks
          ? props.currentUser.attributes.profile.publicData.bookmarks
          : [],
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handleWishlist = this.handleWishlist.bind(this);
  }
  handleWishlist(id, e) {
    const {
      onUpdateProfile,
      currentUser,
      isAuthenticated,
      history,
    } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!isAuthenticated && e && id) {
      // const routes = routeConfiguration();
      // history.push(createResourceLocatorString('LoginPage', routes, {}, {}));

      let localBookmarks =
        typeof window !== 'undefined' &&
          window.localStorage.getItem('localBookmarks') &&
          window.localStorage.getItem('localBookmarks').length > 0
          ? window.localStorage.getItem('localBookmarks')
          : [];

      if (typeof localBookmarks === 'string') {
        localBookmarks =
          typeof window !== 'undefined' &&
          JSON.parse(window.localStorage.getItem('localBookmarks'));
      }

      const localIndex =
        localBookmarks && localBookmarks.findIndex(b => b == id);

      if (localIndex > -1) {
        localBookmarks && localBookmarks.splice(localIndex, 1);
        const removedBookmarks = Array.from(new Set(localBookmarks));
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'localBookmarks',
            JSON.stringify(removedBookmarks)
          );
      } else {
        localBookmarks.push(id);
        const addedBookmarks = Array.from(new Set(localBookmarks));
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'localBookmarks',
            JSON.stringify(addedBookmarks)
          );
      }
    }

    const bookmarks =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks;

    const index = bookmarks && bookmarks.findIndex(b => b == id);

    if (id) {
      if (index > -1) {
        bookmarks && bookmarks.splice(index, 1);
        const removedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: removedBookmarks,
          },
        };
        onUpdateProfile(profile);
      } else {
        this.state.bookmarks.push(id);
        const addedBookmarks = Array.from(new Set(this.state.bookmarks));
        const profile = {
          publicData: {
            bookmarks: addedBookmarks,
          },
        };
        onUpdateProfile(profile);
      }
    }
  }
  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const {
      bookingDates,
      quantity: quantityRaw,
      deliveryMethod,
      ratePerDays,
      selectSize,
      ...otherOrderData
    } = values;
    const bookingDatesMaybe = bookingDates
      ? {
        bookingDates: {
          bookingStart: bookingDates.startDate,
          bookingEnd: bookingDates.endDate,
        },
      }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: Number.parseInt(quantityRaw, 10),
        deliveryMethod,
        ratePerDays,
        selectSize,
        saleOrRent: this.state.saleOrRent,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString(
            'OrderDetailsPage',
            routes,
            { id: txId.uuid },
            {}
          )
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      bookingData,
    } = this.props;
    const isAvail = maxTimeSlots == timeSlots?.length
    const handleRent = () => {
      this.setState({ saleOrRent: 'forRent' })
    }
    const handleSale = () => {
      this.setState({ saleOrRent: 'forSale' })
    }

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant =
      rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug =
      rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;
    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage =
      pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return (
        <NamedRedirect
          name="ListingPage"
          params={params}
          search={location.search}
        />
      );
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage
        id="ListingPage.bookingTitle"
        values={{ title: richTitle }}
      />
    );

    const lb =
      typeof window !== 'undefined' &&
      typeof window.localStorage.getItem('localBookmarks') === 'string' &&
      JSON.parse(window.localStorage.getItem('localBookmarks'));

    const topbar = <TopbarContainer lb={lb} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable &&
      currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser =
      authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed =
        currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(
      currentListing,
      `${config.listing.variantPrefix}-2x`
    ).map(img => img.url);
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand
      ? { brand: { '@type': 'Brand', name: brand } }
      : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter(
      'amenities',
      customConfig.filters
    );
    const categoryOptions = findOptionsForSelectFilter(
      'category',
      customConfig.filters
    );
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    let bookmarks =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks;

    const id = listingId && listingId.uuid;
    const bookmarksArr = isAuthenticated ? bookmarks : lb;
    const unitType =
      publicData && publicData.type && publicData.type == 'For Sale'
        ? config.lineItemSaleUnitType
        : config.lineItemRentUnitType;

    const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.fixedWidthSection}>
              <div className={css.backToResult} onClick={() => {
                history.go(-1);
              }}>
                <svg
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.75"
                    d="M1.80291 5.47496L5.76047 1.32958C5.84174 1.25434 5.90544 1.1606 5.94669 1.05557C5.98794 0.950534 6.00564 0.837001 5.99843 0.72369C5.99122 0.61038 5.95929 0.500306 5.9051 0.401927C5.85091 0.303548 5.77589 0.219481 5.68581 0.156184C5.53587 0.0424687 5.35232 -0.0120626 5.16766 0.00223975C4.983 0.0165421 4.80912 0.0987557 4.6768 0.234334L0.230094 4.92734C0.157577 4.99694 0.0996761 5.08156 0.0600657 5.17584C0.0204549 5.27011 0 5.37197 0 5.47496C0 5.57795 0.0204549 5.67981 0.0600657 5.77408C0.0996761 5.86835 0.157577 5.95298 0.230094 6.02258L4.6768 10.7156C4.80912 10.8512 4.983 10.9334 5.16766 10.9477C5.35232 10.962 5.53587 10.9075 5.68581 10.7937C5.77589 10.7304 5.85091 10.6464 5.9051 10.548C5.95929 10.4496 5.99122 10.3395 5.99843 10.2262C6.00564 10.1129 5.98794 9.99939 5.94669 9.89435C5.90544 9.78932 5.84174 9.69558 5.76047 9.62034L1.80291 5.47496Z"
                    fill="#000"
                  />
                </svg>
                Back to result
              </div>
              <div className={css.contentWrapperForProductLayout}>
                <div className={css.mainColumnForProductLayout}>
                  <SectionGallery listing={currentListing} />
                  {currentListing.id ? (
                    <div className={css.addToCart}>
                      <ActionBarMaybe
                        className={css.actionBarForProductLayout}
                        isOwnListing={isOwnListing}
                        listing={currentListing}
                        editParams={{
                          id: listingId.uuid,
                          slug: listingSlug,
                          type: listingType,
                          tab: listingTab,
                        }}
                      />
                    </div>
                  ) : null}
                  {reviews && reviews.length && !(publicData?.type == "For Sale") ?
                    <SectionReviews
                      reviews={reviews}
                      fetchReviewsError={fetchReviewsError}
                    /> : null
                  }

                </div>
                <div className={css.orderColumnForProductLayout}>
                  <div className={css.productMobileHeading}>
                    <SectionHeading
                      priceTitle={priceTitle}
                      formattedPrice={formattedPrice}
                      richTitle={richTitle}
                      category={category}
                      authorLink={authorLink}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                    />
                  </div>

                  <div className={css.productDetails}>
                    <SectionDescriptionMaybe
                      description={description}
                      listingTitle={richTitle}
                    />
                    <div className={css.sectionMainWrapper}>
                      <div className={css.productDetailsInfo}>
                        {
                          publicData?.type == "For Sale/Rent" ?
                            <>
                              <div className={css.editionText}>
                                <span className={css.listingCategoryTitle}>Rental Rate :</span>€ {Math.round(price.amount / 100)} / 3 days
                              </div>
                              <div className={css.editionText}>
                                <span className={css.listingCategoryTitle}>For Sale Rate :</span> € {Math.round(publicData.salePrice / 100)} / item
                              </div>
                            </>
                            : null
                        }
                        {
                          publicData.type == 'For Sale' ?
                            <div className={css.editionText}>
                              <span className={css.listingCategoryTitle}><FormattedMessage id="ListingPage.resalePrice" />
                              </span>
                              <span>€ {Math.round(publicData.salePrice / 100)}</span>
                            </div>
                            : null

                        }
                        <div className={css.editionText}>
                          <span className={css.listingCategoryTitle}>Category :</span> {publicData.category}
                        </div>
                        <div className={css.editionText}>
                          <span className={css.listingCategoryTitle}>Brand :</span> {publicData.brand}
                        </div>
                        <div className={css.editionText}>
                          <span className={css.listingCategoryTitle}>Colour :</span> {publicData.colour}
                        </div>
                        <div className={css.editionText}>
                          <span className={css.listingCategoryTitle}>Fit :</span> {publicData.fits}
                        </div>
                        <div className={css.editionText}>
                          <span className={css.listingCategoryTitle}>Condition :</span> {publicData.conditions}
                        </div>
                        <SectionDetailsMaybe
                          publicData={publicData}
                          customConfig={customConfig}
                        />
                      </div>
                      <div className={css.price}>
                        <div className={css.priceNumber}>
                          {
                            publicData.type == 'For Rent' ?
                              <>€ {Math.round(price.amount / 100)}</>
                              : publicData.type == 'For Sale' ? <>{formattedMoney(intl, price)}</> : null
                          }
                        </div>

                        {
                          publicData.type == "For Rent" ?
                            <div className={css.priceText}>
                              <FormattedMessage id="ListingPage.priceTextForRental" />
                            </div> :
                            publicData.type == 'For Sale' ?
                              <div className={css.priceText}>
                                <FormattedMessage id="ListingPage.priceText" />
                              </div>
                              : null
                        }
                      </div>
                    </div>
                    <p className={css.description}>
                      {richText(description, {
                        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                        longWordClass: css.longWord,
                      })}
                    </p>
                  </div>

                  <SectionFeaturesMaybe
                    extendedDataKey="amenities"
                    options={amenityOptions}
                    publicData={publicData}
                  />
                  <SectionRulesMaybe publicData={publicData} />
                  {/* <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
               /> */}
                  <OrderPanel
                    className={css.productOrderPanel}
                    handleRent={handleRent}
                    bookingData={bookingData}
                    handleSale={handleSale}
                    saleOrRent={this.state.saleOrRent}
                    listing={currentListing}
                    isAvail={isAvail}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleOrderSubmit}
                    title={bookingTitle}
                    author={ensuredAuthor}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onContactUser={this.onContactUser}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    handleWishlist={this.handleWishlist}
                    bookmarks={bookmarks}
                    currentUser={currentUser}
                  />
                  <div className={css.addToCart}>
                    {bookmarksArr &&
                      bookmarksArr.findIndex(e => e == id) > -1 ? (
                      <button
                        className={css.addBtn}
                        onClick={e => {
                          this.handleWishlist(id, e);
                        }}
                      >
                        Remove from wishlist
                      </button>
                    ) : (
                      <button
                        className={css.addBtn}
                        onClick={e => {
                          this.handleWishlist(id, e);
                        }}
                      >
                        Add to wishlist
                      </button>
                    )}
                  </div>
                  <SectionAuthorMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={
                      isAuthenticated && this.state.enquiryModalOpen
                    }
                    onCloseEnquiryModal={() =>
                      this.setState({ enquiryModalOpen: false })
                    }
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer className={css.footer} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};
ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    bookingData,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    bookingData,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) =>
    dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
